// axios
import axios from 'axios'

const prod = "http://188.166.177.25";
const local = "http://localhost:3000";
let instance = axios.create({
  baseURL: prod,
  headers: {
    'Content-Type': 'application/json',
  }
})
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
instance.interceptors.request.use(function (config) {
  const user = JSON.parse(sessionStorage.getItem('vuex'));
  let token;
  let org_id;
  if (user.owner.isLoggedIn) {
    token = user.owner.user.tokens.access.token;
    org_id =  user.owner.user.user.org_id;
  } else {
    token = '';
    org_id = '';
  }
  config.headers.Authorization =  `Bearer ${token}`;
  config.headers.OrgId =  org_id;
  return config;
});

export default instance
