import Vue from "vue"
import { AclInstaller, AclCreate, AclRule } from "vue-acl"
import router from "@/router"

Vue.use(AclInstaller)

let initialRole = "admin";
// let userInfo = JSON.parse(localStorage.getItem("vue"))
// if(userInfo && userInfo.userRole) initialRole = userInfo.userRole
const user = JSON.parse(sessionStorage.getItem('vuex'));
if (user !== null && user !== undefined && user.owner.isLoggedIn) {
  initialRole = user.owner.user.user.role;
}

export default new AclCreate({
  initial: initialRole,
  notfound: "/pages/not-authorized",
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule("admin").generate(),
    editor: new AclRule("editor").or("admin").generate(),
    // public: new AclRule("public").or("admin").or("editor").generate(),
  }
})
