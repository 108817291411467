import Vue from 'vue';
import Router from 'vue-router';
import layoutsMain from './layouts/main/Main.vue';
import auth from "@/auth/authService";

const localStorageKey = 'loggedIn';
const tokenExpiryKey = 'tokenExpiry';
const loginEvent = 'loginEvent';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [

    {
      path: '',
      component: layoutsMain,
      children: [
        {
          path: '/',
          redirect: '/prapaunit/welcome'
        },
        // ========== Basic ===========
        {
          path: '/prapaunit/welcome',
          name: 'prapaunit-welcome',
          component: () => import('./views/PrapaWelcome.vue'),
          meta: {
            pageTitle: 'หน้าแรก',
            rule: 'admin',
          }
        },
        // ========== Basic ===========
        {
          path: '/pdf_report',
          name: 'pdf_report',
          component: () => import('./views/pdf/BillPaymentReport.vue'),
          meta: {
            pageTitle: 'รายงานขาเข้า',
            rule: 'admin',
          }
        },
        {
          path: '/org',
          name: 'org',
          component: () => import('./views/org/OrgPage.vue'),
          meta: {
            pageTitle: 'ข้อมูลองค์กร',
            rule: 'admin'
          },
        },
        {
          path: '/org_admin',
          name: 'org_admin',
          component: () => import('./views/org/AdminOrgPage.vue'),
          meta: {
            pageTitle: 'ข้อมูลองค์กร',
            rule: 'admin'
          },
        },
        {
          path: '/basic/area',
          name: 'area',
          component: () => import('./views/basic/area/AreaViewPage.vue'),
          meta: {
            pageTitle: 'พื้นที่จัดเก็บ',
            rule: 'admin'
          },
        },
        {
          path: '/basic/route',
          name: 'route',
          component: () => import('./views/basic/route/RouteViewPage.vue'),
          meta: {
            pageTitle: 'เส้นทางจัดเก็บ',
            rule: 'admin',
          }
        },
        {
          path: '/basic/payment-channel',
          name: 'payment-channel',
          component: () => import('./views/basic/payby/PaybyViewPage.vue'),
          meta: {
            pageTitle: 'วิธีการชำระเงิน',
            rule: 'admin',
          }
        },
        {
          path: '/basic/discount',
          name: 'discount',
          component: () => import('./views/basic/discount/DiscountViewPage.vue'),
          meta: {
            pageTitle: 'ผู้ได้รับสิทธิส่วนบุคคล',
            rule: 'admin',
          }
        },
        {
          path: '/basic/meter',
          name: 'meter',
          component: () => import('./views/basic/meter/MeterViewPage.vue'),
          meta: {
            pageTitle: 'ขนาดมิเตอร์ (ค่าธรรมเนียม)',
            rule: 'admin',
          }
        },
        {
          path: '/basic/type_of_user',
          name: 'type-of-user',
          component: () => import('./views/basic/type-user/TypeOfUserPage.vue'),
          meta: {
            pageTitle: 'ประเภทผู้ใช้น้ำ (สูตรค่าน้ำ)',
            rule: 'admin',
          }
        },
        {
          path: '/basic/usage_status',
          name: 'usage-status',
          component: () => import('./views/basic/unuse/UnuseViewPage.vue'),
          meta: {
            pageTitle: 'สถานะการใช้งาน',
            rule: 'admin',
          }
        },
        {
          path: '/basic/officer_information',
          name: 'officer-information',
          component: () => import('./views/basic/user/UserViewPage.vue'),
          meta: {
            pageTitle: 'ข้อมูลเจ้าหน้าที่',
            rule: 'admin',
          }
        },
        {
          path: '/basic/group',
          name: 'group-or-office',
          component: () => import('./views/basic/group/GroupViewPage.vue'),
          meta: {
            pageTitle: 'กลุ่ม/หน่วยงาน',
            rule: 'admin',
          }
        },
        {
          path: '/basic/device',
          name: 'device',
          component: () => import('./views/basic/device/DeviceViewPage.vue'),
          meta: {
            pageTitle: 'เครื่องปริ้น',
            rule: 'admin'
          },
        },
        // {
        //   path: '/basic/bill_config',
        //   name: 'bill-config',
        //   component: () => import('./views/basic/billconfig/BillConfigPage.vue'),
        //   meta: {
        //     pageTitle: 'กำหนดรอบการใช้น้ำเริ่มต้น',
        //     rule: 'admin',
        //   }
        // },
        // ========== Operation ===========
        {
          path: '/operation/set_service',
          name: 'set-service',
          component: () => import('./views/operation/BillManagePage.vue'),
          meta: {
            pageTitle: 'กำหนดรอบบริการ',
            rule: 'admin'
          }
        },
        {
          path: '/operation/set_payment',
          name: 'set-payment',
          component: () => import('./views/operation/SetPayment.vue'),
          meta: {
            pageTitle: 'กำหนดรอบส่งเงิน',
            rule: 'admin'
          }
        },
        {
          path: '/operation/payment',
          name: 'payment',
          component: () => import('./views/operation/Payment.vue'),
          meta: {
            pageTitle: 'ชำระเงิน',
            rule: 'admin'
          }
        },
        {
          path: '/operation/payment_alert',
          name: 'payment-alert',
          component: () => import('./views/operation/PaymentAlertPage.vue'),
          meta: {
            pageTitle: 'แจ้งเตือนชำระหนี้',
            rule: 'admin'
          }
        },
        // ========== Report ===========
        {
          path: '/report/use_water_user',
          name: 'use-water-user',
          component: () => import('./views/report/ReportPage.vue'),
          meta: {
            pageTitle: 'ผู้ใช้น้ำประปา',
            rule: 'admin'
          }
        },
        // =============================
        {
          path: '/user',
          name: 'user',
          component: () => import('./views/members/MemberListPage.vue'),
          meta: {
            pageTitle: 'ผู้ใช้น้ำ',
            rule: 'admin'
          }
        },
        {
          path: '/user/create',
          name: 'user-create',
          component: () => import('./views/members/MemberCreatePage.vue'),
          meta: {
            pageTitle: 'เพิ่มข้อมูลผู้ใช้น้ำ',
            rule: 'admin'
          }
        },
        {
          path: '/user/edit/:userId',
          name: 'user-edit',
          component: () => import('./views/members/MemberEditPage.vue'),
          meta: {
            pageTitle: 'แก้ไขข้อมูลผู้ใช้น้ำ',
            rule: 'admin'
          }
        },
        // {
        //   path: '/path',
        //   name: 'path',
        //   component: () => import('./views/apps/chat/Chat.vue'),
        //   meta: {
        //     pageTitle: 'จัดเส้นทาง',
        //     rule: 'admin',
        //     no_scroll: true,
        //   }
        // },
        // {
        //   path: '/form_save',
        //   name: 'form-save',
        //   component: () => import('./views/form-save/FormSavePage.vue'),
        //   meta: {
        //     pageTitle: 'ใบบันทึก',
        //     rule: 'admin',
        //     parent: "todo",
        //     no_scroll: true,
        //   }
        // },
        {
          path: '/meter',
          name: 'meters-reading',
          component: () => import('./views/meter-reading/MeterReadingPage.vue'),
          meta: {
            pageTitle: 'จดมิเตอร์',
            rule: 'admin',
            no_scroll: true,
          }
        },
        {
          path: '/check',
          name: 'check',
          component: () => import('./views/check/CheckPage.vue'),
          meta: {
            pageTitle: 'ตรวจสอบ',
            rule: 'admin'
          },
        },
        // {
        //   path: '/bill',
        //   name: 'bill',
        //   component: () => import('./views/bill/BillPage.vue'),
        //   meta: {
        //     pageTitle: 'พิมพ์ใบเสร็จ',
        //     rule: 'admin'
        //   },
        // },
        // {
        //   path: '/payment',
        //   name: 'payment',
        //   component: () => import('./views/payment/PaymentPage.vue'),
        //   meta: {
        //     pageTitle: 'ชำระเงิน',
        //     rule: 'admin'
        //   },
        // },
        {
          path: '/report_p32',
          name: 'report-p32',
          component: () => import('./views/report-p32/ReportP32Page.vue'),
          meta: {
            pageTitle: 'ป.32 รายรับประจำวัน / พิมพ์ใบเสร็จ',
            rule: 'admin'
          },
        },
        {
          path: '/debtor_accounts',
          name: 'debtor-accounts',
          component: () => import('./views/debtor-account/DebtorAccountPage.vue'),
          meta: {
            pageTitle: 'บัญชีลูกหนี้',
            rule: 'admin'
          },
        },
        {
          path: '/report_p32_daily',
          name: 'report-p32-daily',
          component: () => import('./views/report-p32/ReportP32DailyPage.vue'),
          meta: {
            pageTitle: 'ป.32 สรุปยอดประจำวัน',
            rule: 'admin'
          },
        },
        {
          path: '/request',
          name: 'request',
          component: () => import('./views/basic/request/RequestPage.vue'),
          meta: {
            pageTitle: 'แบบคำขอใช้น้ำ / สัญญา',
            rule: 'editor',
          }
        },
        {
          path: '/payment_check',
          name: 'payment-check',
          component: () => import('./views/payment-check/ListPage.vue'),
          meta: {
            pageTitle: 'ตรวจสอบชำระเงิน',
            rule: 'admin'
          }
        },
        {
          path: '/excel_payment_check',
          name: 'excel-payment-check',
          component: () => import('./views/payment-check/ExcelChecker.vue'),
          meta: {
            pageTitle: 'ตรวจสอบชำระเงิน(Excel)',
            rule: 'admin'
          }
        },
        {
          path: '/meter_location',
          name: 'meter_location',
          component: () => import('./views/members/MemberLocationListPage.vue'),
          meta: {
            pageTitle: 'ตำแหน่งมาตรวัดน้ำ',
            rule: 'admin'
          }
        },
      ]
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        },
      ]
    },
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router
