export default [
  {
    url: "/org",
    name: "Org Data",
    slug: "org-data",
    icon: "LayersIcon",
    i18n: "OrgData",
  },
  {
    url: '/report_p32',
    name: "ReportP32",
    slug: "reportP32",
    icon: "CalendarIcon",
    i18n: "ReportP32",
  },
  {
    url: '/report/use_water_user',
    name: "Use Water User",
    slug: "use-water-user",
    i18n: "UseWaterUser",
  },
  {
    url: '/debtor_accounts',
    name: "Debtor Accounts",
    slug: "debtor-accounts",
    icon: "ClipboardIcon",
    i18n: "DebtorAccount",
  },
]

