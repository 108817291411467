import Vue from 'vue';
import App from './App.vue';
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
import axios from "./axios.js";
import "./http/requests";
import "./fake-db/index.js";
import '../themeConfig.js';
import '@/firebase/firebaseConfig';
import AuthPlugin from "./plugins/auth";
import acl from './acl/acl';
import './globalComponents.js';
import './assets/scss/main.scss';
import '@/assets/css/main.css';
import router from './router';
import store from './store/store';
import i18n from './i18n/i18n';
import './filters/filters';
import VueClipboard from 'vue-clipboard2';
import VueTour from 'vue-tour';
import VeeValidate from 'vee-validate';
import { VueHammer } from 'vue2-hammer';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import moment from 'moment';
import 'moment-timezone';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

require('./assets/css/iconfont.css');
require('vue-tour/dist/vue-tour.css');

Vue.use(Vuesax);
Vue.use(AuthPlugin);
Vue.use(VueClipboard);
Vue.use(VueTour);
Vue.use(VeeValidate);
Vue.use(VueHammer);
Vue.use('l-map', LMap);
Vue.use('l-tile-layer', LTileLayer);
Vue.use('l-marker', LMarker);
Vue.prototype.$http = axios;
Vue.config.productionTip = false;

Vue.filter('dateTimeFormat', (value) => {
  if (value) {
    const datetime = new Date(value);
    datetime.setFullYear(datetime.getFullYear());
    return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY - HH:mm น.');
  }
});

Vue.filter('dateFormat', (value) => {
  if (value && value !== '-') {
    const datetime = new Date(value);
    datetime.setFullYear(datetime.getFullYear());
    return moment(datetime).tz('Asia/Bangkok').format('DD/MM/YYYY');
  }
  return value;
});

router.beforeEach((to, from, next) => {
  if (!store.getters.isLoggedIn) {
    if (to.path !== '/pages/login') {
      next({ path: '/pages/login' });
    } else {
      next();
    }
  } else {
    if (to.path === '/pages/login') {
      next({ path: '/' });
    } else {
      next();
    }
  }
});

new Vue({
    router,
    store,
    i18n,
    acl,
    render: h => h(App)
}).$mount('#app');
