<template>
  <div class="the-navbar__user-meta flex items-center my-3" v-if="user.userFullname">

    <div class="text-right leading-tight hidden sm:block">
      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <span class="font-semibold">{{ user.userFullname }}</span>
        <vs-icon class="" icon="expand_more"></vs-icon>

        <vs-dropdown-menu class="nav-dropdown">
          <vs-dropdown-item class="flex py-2 cursor-pointer hover:bg-primary hover:text-white"@click="$router.push('/profile')">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">ข้อมูลผู้ใช้</span>
          </vs-dropdown-item>
          <vs-dropdown-item class="flex py-2 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">ออกจากระบบ</span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      user: {}
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout() {
        this.$store.dispatch('logoutSession');
        // if(localStorage.getItem("accessToken")) {
        //   localStorage.removeItem("accessToken")
        //   localStorage.removeItem("org_id")
        //   localStorage.removeItem("userInfo")
        //   localStorage.removeItem("tokenExpiry")
        //   localStorage.removeItem("loggedIn")
        //   this.$router.push('/pages/login').catch(() => {})
        // }

        // Change role on logout. Same value as initialRole of acj.js
        this.$acl.change('admin')
        // localStorage.removeItem('userInfo')

        // This is just for demo Purpose. If user clicks on logout -> redirect
        this.$router.push('/pages/login').catch(() => {})
    },
  },
  created() {
    const user = JSON.parse(sessionStorage.getItem('vuex'));
    this.user = user.owner.user.user;
  }
}
</script>

<style>
  .nav-dropdown {
    width: fit-content !important;
  }
</style>
