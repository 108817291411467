import Vue from 'vue';
import Vuex from 'vuex';
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import owner from  "./user";
import VuexPersist from 'vuex-persist';
Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  reducer: (state) => ({ owner: state.owner }),
  storage: window.sessionStorage
});

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        owner
    },
    strict: process.env.NODE_ENV !== 'production',
    plugins: [vuexLocalStorage.plugin]
})
