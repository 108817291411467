import * as types from './mutation-types';
import jwt from "../http/requests/auth/jwt/index.js";
const localStorageKey = 'loggedIn';
const tokenExpiryKey = 'tokenExpiry';
import router from '@/router'

export default {
  state: {
    isLoggedIn: false,
    user: {}
  },
  mutations: {
    [types.LOGIN] (state) {
      state.pending = true;
    },
    [types.LOGIN_SUCCESS] (state) {
      state.isLoggedIn = true;
      state.pending = false;
    },
    [types.LOGOUT] (state) {
      state.isLoggedIn = false;
      state.user = { };
    },
    [types.SET_USER]: (state, { user }) => {
      state.user = user;
    },
    [types.UPDATE_USER]: (state, user) => {
      state.user.user = user;
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    user: state => state.user
  },
  actions: {
    loginSession({ commit }, payload) {
      return new Promise((resolve,reject) => {
        jwt.login(payload.user.username, payload.user.password, payload.user.org_id).then(response => {
          if(response.data.success) {
            commit('SET_BEARER', response.data.data.tokens.access.token);
            commit(types.SET_USER, { user: response.data.data });
            commit(types.LOGIN_SUCCESS);
            router.push(router.currentRoute.query.to || '/');
            resolve(response);
          }else {
            reject({message: response.data.message});
          }
        }) .catch(error => {
          reject({message: "ชื่อองค์กร ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"});
        });
      })
    },
    logoutSession (context) {
      context.commit(types.LOGOUT);
    },
    updateUser (context, payload) {
      context.commit(types.UPDATE_USER, payload);
    }
  }
};
