export default [
  {
    url: "/org",
    name: "Org Data",
    slug: "org-data",
    icon: "LayersIcon",
    i18n: "OrgData",
  },
  {
    url: null,
    name: "Basic Data",
    icon: "DatabaseIcon",
    i18n: "BasicData",
    submenu: [
      {
        url: '/basic/area',
        name: "Area",
        slug: "area",
        i18n: "Area",
      },
      {
        url: '/basic/route',
        name: "Route",
        slug: "route",
        i18n: "Route",
      },
      {
        url: '/basic/payment-channel',
        name: "Payment Channel",
        slug: "payment-channel",
        i18n: "PaymentChannel",
      },
      {
        url: '/basic/discount',
        name: "Discount",
        slug: "Discount",
        i18n: "Discount",
      },
      {
        url: '/basic/meter',
        name: "Meter",
        slug: "meter",
        i18n: "Meter",
      },
      {
        url: '/basic/type_of_user',
        name: "Type of User",
        slug: "type-of-user",
        i18n: "TypeOfUser",
      },
      {
        url: '/basic/usage_status',
        name: "Usage Status",
        slug: "usage-status",
        i18n: "UsageStatus",
      },
      {
        url: '/basic/officer_information',
        name: "Officer Information",
        slug: "officer-information",
        i18n: "OfficerInformation",
      }
      // {
      //   url: '/basic/bill_config',
      //   name: "Bill Config",
      //   slug: "bill-config",
      //   i18n: "BillConfig",
      // },
    ]
  },
  {
    url: null,
    name: "Operation",
    icon: "SettingsIcon",
    i18n: "Operation",
    submenu: [
      {
        url: '/operation/set_service',
        name: "Set Service",
        slug: "set-service",
        i18n: "SetService",
      },
      {
        url: '/operation/set_payment',
        name: "Set Payment",
        slug: "set-payment",
        i18n: "SetPayment",
      },
      {
        url: '/operation/payment',
        name: "Payment",
        slug: "payment",
        i18n: "Payment",
      },
      {
        url: '/operation/payment_alert',
        name: "Payment Alert",
        slug: "payment-alert",
        i18n: "PaymentAlert",
      },
    ]
  },
  {
    url: null,
    name: "Report",
    icon: "PrinterIcon",
    i18n: "Report",
    submenu: [
      {
        url: '/report/use_water_user',
        name: "Use Water User",
        slug: "use-water-user",
        i18n: "UseWaterUser",
      },
    ]
  },
  {
      url: "/user",
      name: "User",
      slug: "user",
      icon: "UserIcon",
      i18n: "User",
  },
  // {
  //     url: "/path",
  //     name: "Path",
  //     slug: "path",
  //     icon: "MessageSquareIcon",
  //     i18n: "Path",
  // },
  // {
  //     url: "/form_save",
  //     name: "Form Save",
  //     slug: "form-save",
  //     icon: "FilePlusIcon",
  //     i18n: "FormSave",
  // },
  {
      url: "/meter",
      name: "Meters Reading",
      slug: "weters-reading",
      icon: "PlusCircleIcon",
      i18n: "MetersReading",
  },
  {
      url: "/check",
      name: "Check",
      slug: "check",
      icon: "CheckSquareIcon",
      i18n: "Check",
  },
  {
    url: '/payment_check',
    name: "Payment Check",
    slug: "payment-check",
    icon: "CheckCircleIcon",
    i18n: "PaymentCheck"
  },
  {
    url: '/excel_payment_check',
    name: "Excel Payment Check",
    slug: "excel-payment-check",
    icon: "CheckCircleIcon",
    i18n: "ExcelPaymentCheck"
  },
  // {
  //     url: '/bill',
  //     name: "Bill",
  //     slug: "bill",
  //     icon: "FileTextIcon",
  //     i18n: "Bill",
  // },
  // {
  //     url: '/payment',
  //     name: "Payment",
  //     slug: "payment",
  //     icon: "DollarSignIcon",
  //     i18n: "Payment",
  // },
  {
      url: '/report_p32',
      name: "ReportP32",
      slug: "reportP32",
      icon: "CalendarIcon",
      i18n: "ReportP32",
  },
  {
    url: '/report_p32_daily',
    name: "ReportP32Daily",
    slug: "reportP32daily",
    icon: "CalendarIcon",
    i18n: "ReportP32Daily",
  },
  {
      url: '/debtor_accounts',
      name: "Debtor Accounts",
      slug: "debtor-accounts",
      icon: "ClipboardIcon",
      i18n: "DebtorAccount",
  },
  {
    url: '/request',
    name: "Request",
    slug: "Request",
    icon: "ClipboardIcon",
    i18n: "Request",
  },
  {
    url: '/',
    name: "receiveManagement",
    slug: "receiveManagement",
    icon: "ClipboardIcon",
    i18n: "receiveManagement",
  },
  {
    url: '/',
    name: "registerMeterManagement",
    slug: "registerMeterManagement",
    icon: "ClipboardIcon",
    i18n: "registerMeterManagement",
  },
  {
    url: '/',
    name: "receiveManagement",
    slug: "receiveManagement",
    icon: "ClipboardIcon",
    i18n: "receiveManagement",
  },
  {
    url: '/',
    name: "meterManagement",
    slug: "meterManagement",
    icon: "ClipboardIcon",
    i18n: "meterManagement",
  },
  {
    url: '/',
    name: "waterCost",
    slug: "waterCost",
    icon: "ClipboardIcon",
    i18n: "waterCost",
  },
  {
    url: '/',
    name: "loseWater",
    slug: "loseWater",
    icon: "ClipboardIcon",
    i18n: "loseWater",
  },
  {
    url: '/meter_location',
    name: "gis",
    slug: "gis",
    icon: "ClipboardIcon",
    i18n: "gis",
  },
  {
    url: '/',
    name: "contactUs",
    slug: "contactUs",
    icon: "ClipboardIcon",
    i18n: "contactUs",
  },
]

